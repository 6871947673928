import { FileObject } from "@/utils/supabase/types"
import { type ClassValue, clsx } from "clsx"
import { twMerge } from "tailwind-merge"

export function cn(...inputs: ClassValue[]) {
  return twMerge(clsx(inputs))
}

export function isFile(entry: FormDataEntryValue): entry is File {
  return entry instanceof File
}

type FileWithURL = File & { url?: string }

export function convertFileObjetArrayToFileArray(
  attachments: FileObject[] | null,
): { file: File }[] {
  const files: { file: FileWithURL }[] = []
  attachments?.forEach((attachment) => {
    const newFile = new File(
      [new Blob()], // Content (optional)
      attachment.name,
      { type: attachment.metadata["mime_type"] || "application/octet-stream" }, // Mimetype (optional)
    ) as FileWithURL
    //@ts-ignore
    newFile.url = attachment.url
    files.push({ file: newFile })
  })
  return files
}

export function generateUUIDV4(): string {
  return "xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx".replace(/[xy]/g, function (c) {
    const r = (Math.random() * 16) | 0
    const v = c === "x" ? r : (r & 0x3) | 0x8
    return v.toString(16)
  })
}
