"use client"

import { z } from "zod"
import { zodResolver } from "@hookform/resolvers/zod"
import { useForm } from "react-hook-form"
import { Button } from "@/components/ui/button"
import Link from "next/link"
import { Input } from "@/components/ui/input"

import { useRouter } from "next/navigation"
import { login } from "../actions"
import { Form, FormField, FormItem, FormMessage } from "@/components/ui/form"
import { cn } from "@/utils/utils"
import { Label } from "@/components/ui/label"
// import { useUrlMaker } from "@/utils/useUrlMaker"

export const formSchema = z.object({
  email: z
    .string()
    .min(1, { message: "ce champ est requis" })
    .email("Le format attendu est: texte@nom.domaine. Ex: Luc.boulanger@gmail.com"),
  password: z.string().min(1, { message: "ce champ est requis" }),
  rememberMe: z.boolean(),
})

export default function LoginPage({
  className,
  ...props
}: React.ComponentPropsWithoutRef<"form">) {
  // const { app } = useUrlMaker()
  const router = useRouter()

  const form = useForm<z.infer<typeof formSchema>>({
    resolver: zodResolver(formSchema),
    defaultValues: {
      email: "",
      password: "",
      rememberMe: true,
    },
  })

  async function onSubmit(values: z.infer<typeof formSchema>) {
    // TODO: remember me input

    const data = {
      email: values.email as string,
      password: values.password as string,
      rememberMe: values.rememberMe as boolean,
    }
    const { error: _error, data: _data } = await login(data)

    if (_error) {
      console.error(_error)
      form.formState.errors.password = {
        message: "Adresse mail ou mot de passe incorrecte",
        type: "custom",
      }
      form.formState.errors.email = {
        message: " Adresse mail ou mot de passe incorrecte ",
        type: "custom",
      }
    } else if (_data) {
      router.push("/fr/app/task")
    }
  }

  return (
    <Form {...form}>
      <form
        className={cn("flex flex-col gap-6", className)}
        {...props}
        onSubmit={form.handleSubmit(onSubmit)}
      >
        <div className="flex flex-col items-center gap-2 text-center">
          <h1 className="text-2xl font-bold">Se connecter à Travo®</h1>
          <p className="text-balance text-sm text-muted-foreground">
            Connectez-vous avec votre identifiant et votre mot de passe associé à votre
            compte
          </p>
        </div>
        <div className="grid gap-6">
          <div className="grid gap-2">
            <FormField
              control={form.control}
              name="email"
              render={({ field }) => (
                <FormItem className="space-y-1">
                  {/* <FormLabel>Email</FormLabel>
                  <FormControl>
                    <Input placeholder="" {...field} />
                  </FormControl>
                  <FormMessage /> */}
                  <Label htmlFor="email">Adresse email</Label>
                  <Input
                    id="email"
                    type="email"
                    placeholder="m@example.com"
                    required
                    {...field}
                  />
                  <FormMessage />
                </FormItem>
              )}
            />
          </div>
          <div className="grid gap-2">
            <FormField
              control={form.control}
              name="password"
              render={({ field }) => (
                <FormItem className="space-y-1">
                  {/* <FormLabel>Mot de passe</FormLabel>
                  <FormControl>
                    <PasswordInput id="password" {...field} />
                  </FormControl>
                  <FormMessage /> */}
                  <div className="flex items-center">
                    <Label htmlFor="password">Mot de passe</Label>
                    <Link
                      href="/fr/password-forgotten"
                      className="ml-auto text-sm underline-offset-4 hover:underline"
                    >
                      Mot de passe oublié ?
                    </Link>
                  </div>
                  <Input id="password" type="password" required {...field} />
                  <FormMessage />
                </FormItem>
              )}
            />
          </div>

          <Button variant="forecast" type="submit" className="w-full">
            Me connecter
          </Button>
        </div>
      </form>
    </Form>
  )
}
